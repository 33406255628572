import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Fitness from "./pages/fitness";
import Circuit from "./pages/circuit";
import Home from "./pages/home";
import Gym from "./pages/gym";
import GWLP from "./pages/gwlp";
import ConceptHer from './pages/conceptHer';
import NotFoundPage from "./pages/notFound";
import Contact from "./pages/contact";
import Shred30 from "./pages/shred30";
import ThankYou from "./pages/thankYou";
import PrivacyPolicy from "./pages/privacy-policy";
import Ramadan from "./pages/ramadan"
import Pregnant from "./pages/pregnant";
import Revival from "./pages/revival";
import Youssef from './pages/youssef'
import RockClimbing from "./pages/rockClimbing";
import Hiking from "./pages/hiking";
import Footer from "./components/footer";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  // Check if current path is home page
  const isHomePage = pathname === "/";

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/free-360-body-analysis" element={<Fitness />} />
        <Route path="/circuit-gym1" element={<Circuit />} />
        <Route path="/gym1" element={<Gym />} />
        <Route path="/cgwlp30" element={<GWLP />} />
        <Route path="/contact-us1" element={<Contact />} />
        <Route path="/concept-her1" element={<ConceptHer />} />
        <Route path="/shred-30" element={<Shred30 />} />
        <Route path="/revival" element={<Revival />} />
        <Route path="/pregnant-mama" element={<Pregnant />} />
        <Route path="/ramadan" element={<Ramadan />} />
        <Route path="/thank-you-page1669060964003" element={<ThankYou />} />
        <Route path="Youssef" element={<Youssef />} />
        <Route path="rockClimbing" element={<RockClimbing />} />
        <Route path="/hiking" element={<Hiking />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {!isHomePage && <Footer />} {/* Render Footer only if not on home page */}
    </>
  );
}

export default App;