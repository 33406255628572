import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Facebook } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-100 text-gray-600 py-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
          {/* Copyright */}
          <div className="text-sm">
            &copy; {new Date().getFullYear()} AOS
          </div>

          {/* Location */}
          <div className="text-sm text-center px-4 max-w-[200px] md:max-w-none">
            📍 AlGuezira Compound, Al Sheikh Zayed, 6 October City
          </div>

          {/* Contact & Links */}
          <div className="flex items-center space-x-4 text-sm">
            <Link to="/privacy-policy" className="hover:text-gray-900">
              Privacy Policy
            </Link>
            
            {/* Social Icons */}
            <div className="flex space-x-3">
              <a 
                href="https://www.instagram.com/aos.fit/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-gray-900"
              >
                <Instagram size={20} />
              </a>
              <a 
                href="https://www.facebook.com/AOSegypt" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-gray-900"
              >
                <Facebook size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;