import React from "react";

export default function WhyConcept(props) {
    return (
        <>
            <div className="container1">
                <img loading="lazy"
                    src="/backgroundConcept.jpg"
                    className="image" />
                <div className="content">
                    <div className="title">
                    <span style={{ color: "rgba(47,47,47,1)" }}>WHY </span>
                        <a
                            href="#"
                            className="atag1"
                            target="_blank"
                        >
                            CONCEPT HER
                        </a>
                        <span style={{ color: "rgba(47,47,47,1)" }}> ?</span>
                    </div>
                    <div className="subtitle">Need guidance</div>
                    <div className="subtitle">Bored of training alone</div>
                    <div className="subtitle">Privates are very expensive</div>
                    <div className="subtitle">Don't know what or how to eat</div>
                    <div className="subtitle">Don’t know from where or how to start</div>
                    <div className="subtitle">Want to keep track of your performance</div>
                    <div className="subtitle">
                        Wasting too much time and not seeing results
                    </div>
                    <div className="subtitle">
                        Paid gym membership and only went for couple of times
                    </div>
                    <br />
                    <div className="subtitle">
                        WELL, OUR REVOLUTIONARY PROGRAM GOT ALL THESE POINTS COVERED!
                    </div>
                    <div className="link">
                        <a href="#" className="atag1">
                            EVERYTHING YOU NEED TO LOSE WEIGHT AND GET TONED IN ONE PROGRAM
                        </a>
                    </div>
                </div>
            </div>
            <style jsx>{`
        .container1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          position: relative;
          display: flex;
          min-height: 366px;
          padding: 0 20px;
        }
        .image {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .atag1 {
            color: rgba(255, 0, 203, 0.85);
            text-decoration: none;
        }
        .atag1:hover {
                border-bottom: 4px solid  rgba(255, 0, 203, 0.85);
        }
        .content {
          position: relative;
          display: flex;
          margin-top: 20px;
          width: 816px;
          max-width: 100%;
          flex-direction: column;
        }
        .title {
          color: var(--www-aos-fit-red, #e00);
          text-align: center;
          align-self: center;
          margin-bottom: 20px;
          white-space: nowrap;
          font: 700 30px/42px Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .title {
            white-space: initial;
          }
        }
        .subtitle {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 400 16px/24px Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .subtitle {
            white-space: initial;
          }
        }
        .link {
          color: var(--www-aos-fit-scarlet, #f31400);
          text-align: center;
          align-self: stretch;
          margin-top: 22px;
          white-space: nowrap;
          font: 700 21px/26px Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .link {
            max-width: 100%;
            white-space: initial;
          }
        }
      `}</style>
        </>
    );
}